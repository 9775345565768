.abouttitle{
    text-align: left;
    color: #162c31;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 70px 0 30px;
}

.abouttitle h2{
    font-size: 32px;
    color: #022c3c;
    margin-top: 5px;
    text-transform: none;
}