nav{
    width: 100%;
    /* background: #333; */
    color: #fff;
    padding: 8px 0;
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}



.logo{
    width: 80px;
}

nav ul li{
    display: inline-block;
    list-style: none;
    margin: 5px 20px;
    font-size: 16px;
}

.dark-nav{
    background: #162c31;
    transition: 0.5s;
}

.menu-icon{
    display: none;
}
/* media query */

@media (max-width: 1000px){
    .logo{
        width: 60px;
    }
    nav ul li{
        margin: 10px 15px;
    }
}

@media (max-width:840px){
    nav{
        padding: 15px 0;;
    }
    nav ul{
        position:fixed;
        top: 0;
        right: 0px;
        bottom: 0;
        background: #162c31;
        z-index: -1;
        width: 200px;
        padding-top: 70px;
        transition: 0.5s;
    }
    nav ul li{
        display: block;
        margin: 25px 40px;
    }
    .menu-icon{
        display: block;
        width: 30px;
        cursor: pointer;
    }
    .hide-mobile-menu{
        right: -200px
    }
}