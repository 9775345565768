/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
}

h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.buttonProduct button{
    margin-top: 70px;
    margin-bottom: 30px;
    margin-left: 80px;
    flex-wrap: nowrap;
    overflow-x: auto; 
}


.card {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 15px; 
    flex: 0 0 auto; 
    width: 1000px; 
}

.card-img-top {
    text-align: center;
    padding: 20px 0;
}

.card-img-top img {
    max-width: 100%;
    height: auto;
}

.card-body {
    padding: 20px;
}

.card-title {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.card-text {
    color: #6c757d;
    font-size: 1rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -15px;
    margin-left: -15px; 
}

.col-md-4 {
    flex: 0 0 calc(33.333% - 20px); 
    max-width: calc(33.333% - 30px); 
    padding-right: 15px; 
    padding-left: 15px; 
}

.col-md-4:nth-child(3n) {
    padding-right: 0;
}

@media (max-width: 850px) {
    .row{
        flex-direction: column;
    }
    .row h1{
        text-align: left;
    }
    .col-md-4 {
        flex-basis: 100%;
        margin: 100px;
    }

}

@media (max-width: 650px) {
    .row{
        flex-direction: column;
    }
    .row h1{
        text-align: left;
    }
   

    .col-md-4 {
        flex-basis: 100%;
        margin: 20px;
    }

    .col-md-4:nth-child(2n) {
        margin-right: 0;
    }
} */

/* Reset default margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Body styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
}

/* Container styles */
/* .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
} */

/* Page header styles */
h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.buttonProduct {
    margin-top: 30px;
    margin-bottom: 50px;
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    overflow-x: auto; /* Allow scrolling for extra buttons */
}

.buttonProduct button {
    flex: 0 0 auto; /* Prevent buttons from growing to fill space */
}

/* Card styles */
.card {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 1000px; 

    margin-right: 15px; /* Adjust margin between cards */
    flex: 0 0 auto; /* Prevent cards from growing to fill space */
}

.card:last-child {
    margin-right: 0; /* Remove right margin for the last card in the row */
}

.card-img-top {
    text-align: center;
    padding: 20px 0;
}

.card-img-top img {
    max-width: 100%;
    height: auto;
}

.card-body {
    padding: 20px;
}

.card-title {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.card-text {
    color: #6c757d;
    font-size: 1rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -15px; /* Adjust for negative margin */
    margin-left: -15px; /* Adjust for negative margin */
}

.col-md-4 {
    flex: 0 0 calc(33.333% - 20px); /* 33.333% width for 3 columns with margin */
    padding-right: 15px; /* Adjust for column spacing */
    padding-left: 15px; /* Adjust for column spacing */
}

@media (max-width: 1200px) {
    .container {
        padding: 0 10px; /* Adjust container padding for smaller screens */
    }
}

@media (max-width: 992px) {
    .container {
        padding: 0 15px; /* Reset container padding for medium screens */
    }

    .card {
        width: calc(50% - 20px); /* 50% width for 2 cards in a row with margin */
    }

    .card:nth-child(2n) {
        margin-right: 0; /* Remove right margin for the last card in the row */
    }

    .buttonProduct button {
        flex-basis: 50%; /* 50% width for buttons in two columns */
    }
}

@media (max-width: 650px) {
    .row {
        flex-wrap: wrap; /* Allow cards to wrap */
    }
    .card {
        width: calc(100% - 20px); /* 100% width for 1 card in a row with margin */
        flex-basis: 100%;
    }
    .buttonProduct{
        justify-content: center;
    }

    .buttonProduct button {
        flex-basis: 50%; /* 100% width for buttons in one column */
    }
}

