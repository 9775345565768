.services{
    margin: 80px auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.service img {
    width: 100%;
    border-radius: 10px;
    display: block;
}

.service{
    flex-basis: 31%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.caption{
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(1, 36, 45, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    cursor: pointer;
    opacity: 0;
    padding-top: 70%;
    transition: 0.4s;
}

.caption img{
    width: 60px;
    margin-bottom: 10px;
}
.service:hover .caption{
    opacity:1;
    padding-top: 0;
}

/* media query */

@media (max-width:650px){
    .services{
        flex-direction: column;
    }
    .service{
        flex-basis: 100%;
        margin: 20px;
    }
}