.footer{
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #797979;
    padding: 15px 0;
}

.footer ul {
    display: flex;
    list-style: none;
    padding: 0;
}

.footer ul li {
    margin-left: 20px;
}

.social-icons {
    display: flex;
}

.social-icons a {
    color: inherit;
    margin-left: 15px;
    font-size: 1.5rem;
    text-decoration: none;
}

/* media query */
@media (max-width:600px){
    .footer{
        display: block;
        text-align: center;
    }
    .footer ul {
        justify-content: center;
        margin-top: 10px;
    }
    .footer ul li {
        margin: 10px;
    }
    .social-icons {
        justify-content: center;
        margin-top: 10px;
    }
}
