.ProductHead {
    display: flex;
    width: 100%;
    min-height: 50vh;
    background: linear-gradient(rgba(94, 93, 104, 0.7),rgba(29, 28, 33, 0.7)), url("../../Assets/productpage.png");
    background-size:auto;
    background-position: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: left;
}

.ProductHead-text {
    text-align: Left;
    max-width: 800px;
}

.ProductHead-text h1 {
    text-align: left;
    font-size: 60px;
    font-weight: 600;
}

.ProductHead-text p {
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
}

/* media query */

@media (max-width: 850px) {
    .ProductHead-text h1 {
        font-size: 40px;
    }
}

@media (max-width: 650px) {
    .ProductHead-text h1 {
        font-size: 30px;
        max-width: 400px;
        margin: auto;
    }
    .ProductHead-text p {
        font-size: 14px;
        margin: 15px auto 30px;
    }
}
