/* ServiceCustom.css */
/* .service-custom {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  
  .service-item {
    margin-bottom: 100px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 5px;
  }
  
  .service-item h3 {
    margin-top: 0;
    font-size: 24px;
  }
  
  .image-row {
    display: flex;
    margin: 40px auto;
    justify-content: space-between;
  }
  
  .image-row img {
    border-radius: 10px;
    width: 30%;
    height: auto;
  }
  
  .service-item p {
    margin-top: 20px;
    color: #666;
    font-size: 14px;
  } */

.servicescustom {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

}

.servicetitle{
  /* text-align: left;
  color: #000F38;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 70px 0 30px; */
  flex-basis: 56%;
}

.servicetitle h2{
  font-size: 32px;
  color:#0e5061;
  text-transform: none;
  margin: 40px 0;
}
.servicetitle p{
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 1.6;
}

.servicetitle li{
  color: #403f3f;
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 1.6;
}

.servicestatus p{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.servicesrow {
    margin: 30px auto;
    margin-bottom: 100px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content:space-around;
}

.servicecus img {
    width: 100%;
    border-radius: 10px;
    display: block;
}

.servicecus {
    flex-basis: 31%;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.captioncus {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(1, 36, 45, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    cursor: pointer;
    opacity: 0;
    padding-top: 70%;
    transition: 0.4s;
}

.servicecus:hover .captioncus {
    opacity: 1;
    padding-top: 0;
}

/* media query */

@media (max-width:650px) {
    .servicesrow {
        flex-direction: column;
    }

    .servicecus {
        flex-basis: 100%;
        margin: 20px;
    }
    .servicetitle p{
      flex-basis: 100%;
    }
}
